<template>
  <div class="container">
    <div class="FixedImg logo">
      <img src="../assets/images/logo_title.png"/>
      <div class="logoTitle">
<!--        <div>5G场景“旅游+”智慧化服务技术研发与应用</div>-->
        <p>课题 1：5G 场景“旅游+”沉浸式体验技术研发与应用</p>
        <p>课题 2：5G 场景 “旅游+” 一体化数据中台研发与应用</p>
      </div>
    </div>
    <div class="set">
      <label>{{ username }}</label>
      <img src="../assets/images/daohang.png" style="margin:0 10px;" title="导航地图" @click="goMap">
      <img src="../assets/images/index/set.png" @click="logoutFnc">
    </div>


    <div class="index-content">

      <div class="menuBox" v-for="(item,index) in models" :class="index===0?'havChild':'havChild2'" :key="item.id"
           @click="jumpPage(item.moduleId,item.url,item.target)">
        <img class="img1" :src="Api.imgUrl+item.icon" alt="">
        <img class="img2" :src="Api.imgUrl+item.iconActive" alt="">
        <div class="subMenu" v-if="item.child.length>0 && item.isAuth">
          <div class="item" v-for="ite in item.child" v-on:click.stop="jumpPage(ite.moduleId,ite.url,ite.target)">{{ ite.name }}
          </div>
        </div>
      </div>
      <img class="bg" src="../assets/images/index/menu_bg.png">
      <img class="bg1" src="../assets/images/index/menu_bg1.png">
      <img class="bg2" src="../assets/images/index/menu_bg2.png">
      <img class="bg3" src="../assets/images/index/menu_bg3.png">
    </div>

    <!-- 登录过期提示 -->
    <div class="mask" v-if="loginVal"></div>
    <div class="FixedImg loginTips" v-if="loginVal" @click="nologin">
      <span>{{ loginText }}</span>
      <p>×</p>
    </div>
    <!-- 无权限提示 -->
    <div class="FixedImg loginTips" v-if="powerVal" @click="noPower">
      <span>暂无访问权限，请联系管理员~</span>
      <p>×</p>
    </div>

    <!-- 二维码弹窗 -->
    <div class="maskBox" v-show="showQrcode">
      <div class="qrCode">
        <img :src="qrCodePath" alt="">
        <img @click="showQrcode = false" class="closeIcon" src="../assets/images/index/icon-close.png" alt="">
      </div>
    </div>

    <!-- 技术支持 -->
    <div class="jszc">
      版权所有：广西师范大学、浙江天迈文化科技有限公司、龙胜各族自治县全域旅游投资开发有限公司<span>|</span><a target="_blank" href="https://beian.miit.gov.cn">ICP证：浙ICP备16041757号-1</a>
    </div>
  </div>
</template>

<script>
import Api from "@/server";
export default {
  name: 'index',
  data() {
    return {
      username: '',
      // 登录过期提示
      loginVal: false,
      loginText: '',
      showQrcode: false,
      authList: [],
      qrCodePath: '',
      models: [],
      // 无权限提示
      powerVal: false,
      powerText: '',
      Api: Api,
      arrs: [1, 2, 3],

      powerArr: [],
      getToken: '',
    }
  },
  methods: {
    getAuth() {
      let params = {
        systemId: "sso"
      }
      Api.ajaxRequest('auth/query/permission', params).then(res => {
        let data = res.data
        this.authList = data
        this.getModels()
      })
    },
    getModels() {
      let params = {
        data: {
          systemId: "sso"
        }
      }
      Api.ajaxRequest('module/query/tree', params).then(res => {
        let data = res.data
        data.forEach((item, index) => {
          this.authList.forEach((ite, indx) => {
            if(ite.id === item.id){
              item.isAuth = true
            }
          })
        })
        this.models = data
      });
    },
    goMap(){
       window.open('/data/#/webmap')
    },
    GetUserName() {
      let params = Api.formDataArg()
      Api.ajaxRequestas('user/get/info', params).then(res => {
        let {code, data} = res;
        if (code !== 200) {
          if (code === 503) {
            this.loginVal = true;
            this.loginText = '登录信息已过期,请重新登录';
          } else {
            this.loginVal = true;
            this.loginText = res.description;
          }
        } else {
          this.username = data.realName
        }
      });
    },
    // 无权限提示
    noPower() {
      this.powerVal = false;
    },
    // menuGoto(path, id, child, name) {
    //   let leftId = id,
    //       leftName = name,
    //       moduleId,
    //       requset_moduleId = '';
    //   if (path == null || path == '') {
    //     if (child[0].url == null || child[0].url == '') {
    //       path = child[0].child[0].url;
    //       leftId = child[0].child[0].id;
    //       leftName = child[0].child[0].name;
    //       requset_moduleId = child[0].child[0].moduleId;
    //     } else {
    //       path = child[0].url;
    //       leftId = child[0].id;
    //       leftName = child[0].name;
    //       requset_moduleId = child[0].moduleId;
    //     }
    //   }
    //   // 当菜单只有本级时
    //   let btnList = [];
    //   if (child.length > 0) {
    //     // 判断是否为按钮数组
    //     child[0].child.forEach(item => {
    //       if (item.type == '按钮' && item.child.length == 0) {
    //         btnList.push(item);
    //       } else if (item.type !== '按钮' && leftId == item.id) {
    //         btnList = [];
    //         item.child.forEach(el => {
    //           if (el.type == '按钮') {
    //             btnList.push(el);
    //           }
    //         })
    //       }
    //     })
    //   }
    //   moduleId = child[0] ? child[0].moduleId : -1;
    //   let activeItems = [];
    //   child.forEach((item, index) => {
    //     activeItems.push(index)
    //   })
    //   sessionStorage.setItem('admin_MeunInd', id);
    //   sessionStorage.setItem('admin_LeftId', leftId);
    //   sessionStorage.setItem('admin_pageName', leftName);
    //   sessionStorage.setItem('admin_moduleId', moduleId);
    //   sessionStorage.setItem('admin_btnList', JSON.stringify(btnList));
    //   sessionStorage.setItem('admin_activeItems', JSON.stringify(activeItems));
    //   sessionStorage.setItem("requset_moduleId", requset_moduleId);
    // },
    jumpPage(moduleId, url, target) {
      //console.log(moduleId,'woshidage')
      let parentAreaIds = (arr, moduleId) => {
        for (let i in arr) {
          if (arr[i].moduleId === moduleId) {
            return true
          }
          if (arr[i].child.length>0) {
            let node = parentAreaIds(arr[i].child, moduleId);
            if(node){
               return true
            }
          }
        }
      }
      if(!parentAreaIds(this.authList, moduleId)){
        this.powerVal = true
        return false
      }
      if (url.indexOf('/appfile/')>=0) {
        this.qrCodePath = url
        this.showQrcode = true
        return false
      }
      if (url === '' || url === null) {
        return false
      }
      if (url.indexOf('http') < 0) {
        // if (url.indexOf('moduleId=') >= 0) {
        //   let params = {
        //     moduleId: url.split('moduleId=')[1],
        //     systemId: 'admin'
        //   }
        //   Api.ajaxRequest('auth/query/permission', params).then(res => {
        //     let moduleInfo = res.data[0]
        //     this.menuGoto(moduleInfo.url, moduleInfo.id, moduleInfo.child, moduleInfo.name)
        //     //console.log(config.name+url,target)
        //     window.open(config.name + url.split('moduleId=')[0], target)
        //   })
        // } else {
          window.open(config.name + url, target)
        // }
      } else {

        window.open(url, target)
      }
    },
    nologin() {
      this.loginVal = false;
      this.$router.push({path: '/'});
    },

    // 退出
    logoutFnc() {
      localStorage.clear()
      let expireDate = new Date(); //获取时间
      expireDate.setTime(expireDate.getTime() + 100); //保存的天数
      window.document.cookie = "uid=null;path=/;expires=" + expireDate;
      window.document.cookie = "token=null;path=/;expires=" + expireDate;
      this.$router.push({path: '/'})
    },
  },
  mounted() {
    this.getToken = Api.getToken();
    this.GetUserName()
    this.getAuth()
  }
}
</script>

<style scoped lang="less">

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-image: url(../assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 170px;
  overflow: hidden;

  .jszc {
    font-size: 15px;
    color: #ffffff;
    z-index:99;
    a {
      color: #ffffff;
      text-decoration: none;
    }

    span {
      padding: 0 15px;
    }

  }

  .set {
    position: absolute;
    right: 20px;
    top: 24px;
    width: auto;
    height: 33px;
    display: flex;
    align-items: center;

    img {
      width: 33px;
      height: 33px;
      display: inline-block;
      cursor: pointer;
    }

    label {
      display: inline-block;
      height: 33px;
      line-height: 28px;
      color: #ffffff;
      padding-right: 10px;
      font-size: 16px;
    }
  }

  .FixedImg {
    position: fixed;
    transform: translate(-50%, -50%);
    display: flex;

    img {
      width: 100%;
      height: auto;
    }
  }

  .logo {
    width: 50%;
    top: 13%;
    left: 50%;
    display: flex;
    justify-content: center;

    img {
      width: 84px;
      height: 88px;
      margin-right: 20px;
    }

    .logoTitle{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 35px;
      color: #ffffff;
      >div{
        font-weight: 500;
        margin-bottom:5px;
      }
      >p{
        font-weight: 200;
        font-size:26px;
        line-height:1.5em;
      }
    }
  }

  .poetry {
    width: 87px;
    top: 50%;
    left: 5%;
  }

  .sun {
    width: 145px;
    top: 10%;
    right: 0%;
  }


  // 登录提示
  .mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
  }

  .loginTips {
    width: 364px;
    height: 34px;
    background-image: url(../assets/images/login/icon-loginTip.png);
    background-size: 100% 100%;
    color: #1691fb;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    p {
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }
  }

  .index-content {
    width: 70vw;
    height: 70vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .bg {
      width: 1600px;
      height: 1600px;
      position: absolute;
      z-index: 99;
      top: 63%;
      left: 50%;
      animation: rote1 10s infinite linear;
    }

    .bg1 {
      width: 1920px;
      height: 1920px;
      position: absolute;
      z-index: 99;
      top: 62%;
      left: 50%;
      animation: rote2 20s infinite linear;
    }

    .bg2 {
      width: 339px;
      height: 165px;
      position: absolute;
      z-index: 100;
      top: 46%;
      left: 53%;
      animation: rote3 2s infinite linear;
    }

    .bg3 {
      width: 1920px;
      height: 660px;
      position: absolute;
      z-index: 99;
      top: 62%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .menuBox {
      position: absolute;
      width: 255px;
      height: 208px;
      cursor: pointer;
      z-index: 100;

      &.havChild {
        &:hover {
          .subMenu {
            display: flex;
            animation: fadeInRight 500ms ease-in-out;
          }
        }
      }
      &.havChild2 {

        &:hover {
          .subMenu {
            left:auto;
            right: -260px;
            display: flex;
            animation: fadeInLeft 500ms ease-in-out;
          }
        }
      }

      .subMenu {
        background: url("../assets/images/index/dialog.png") no-repeat;
        width: 329px;
        height: 469px;
        background-size: contain;
        position: absolute;
        bottom: -30%;
        left: -260px;
        // display:flex;
        flex-direction: column;
        padding: 30px 0;
        display: none;

        .item {
          width: 243px;
          margin: 0 auto;
          height: 55px;
          padding:8px 0;
          font-size: 16px;
          color: #ffffff;
          display: flex;
          align-items: center;
         // transition: all 0.3s;
          justify-content: center;

          &:hover {
            background: url("../assets/images/index/bg.png") no-repeat center center;
            background-size: contain;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        display: none;
      }

      .img2 {
        display: block;
      }

      .qrCodeBox {
        position: absolute;
        width: 237px;
        height: 247px;
        right: 0;
        top: 0;
        transform: translate(100%);
        background-image: url(../assets/images/index/bg_qrcode.png);
        background-size: 100% 100%;

        .zzdLogin {
          width: 115px;
          height: 115px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -56%);
        }

        .close {
          width: 25px;
          height: 25px;
          position: absolute;
          right: 0;
          transform: translate(50%, 50%);
          z-index: 20;
        }
      }
    }

    .menuBox:hover {
      .img1 {
        display: block;
      }

      .img2 {
        display: none;
      }
    }

    .menuBox:nth-child(2) {
      top: 5%;
      left: 26%;
    }

    .menuBox:nth-child(3) {
      top: 5%;
      right: 26%;
    }

    .menuBox:nth-child(4) {
      top: 35%;
      right: 5%;
    }

    .menuBox:nth-child(1) {
      top: 35%;
      left: 5%;
    }
  }
}

.maskBox {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  z-index: 101;
  position: fixed;

  .qrCode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 326px;
    height: 323px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/images/index/bg_1.png);
    background-size: 100% 100%;

    img {
      width: 225px;
      height: 217px;
    }

    .closeIcon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
    }
  }
}

@keyframes rote1 {
  0% {
    transform: translate(-50%, -50%) rotateX(74deg) rotateZ(0);
  }
  100% {
    transform: translate(-50%, -50%) rotateX(74deg) rotateZ(359deg);
  }
}

@keyframes rote2 {
  0% {
    transform: translate(-50%, -50%) rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: translate(-50%, -50%) rotateX(75deg) rotateZ(-359deg);
  }
}

@keyframes rote3 {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -20%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
</style>
